



























import { Vue, Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';

@Component
export default class ProductList extends mixins(DataFormat) {
  @Prop({
    required: true
  })
  public productList!: any;

  public swiper: any = null;

  public mounted() {
    this.activeSwiper();
  }
  public updated() {
    this.activeSwiper();
  }
  public activeSwiper() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    const swiper = new Swiper('.product-list-container .swiper-container', {
      spaceBetween: -30,
      centeredSlides: true,
      observer: true,
      observeParents: true,
    });
  }
}
